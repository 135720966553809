<template>
  <v-container fluid @dragover="preventDefaultDragOver">
    <v-row align="start">
      <v-col class="mb-0 pb-0" cols="12" v-if="$vuetify.breakpoint.mdAndDown">
        <v-slide-group show-arrows>
          <v-slide-item v-for="item in simsImages" :key="item.id">
            <div class="black--text text-caption text-center mx-8">
              <p class="mb-0">{{ $t(item.displayedName) }}</p>
              <v-row class="fill-height pb-4" align="center" justify="center">
                <div>
                  <v-img
                    class="imagePointer"
                    :id="item.id"
                    :src="
                      require('@/assets/images/sims/v' +
                        getCurrentImageVersion(item) +
                        '/' +
                        item.imageName)
                    "
                    draggable="true"
                    @pointerdown="onImageSelect"
                    @mousedown="onImageSelect"
                    :width="item.width"
                    :height="item.height"
                  />
                </div>
              </v-row>
            </div>
          </v-slide-item> </v-slide-group
      ></v-col>
      <v-col class="mt-0 pt-0 stickyKonva" cols="12" lg="7">
        <v-row
          :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'space-between'"
        >
          <v-col
            v-if="!$vuetify.breakpoint.mdAndDown"
            cols="2"
            align-self="start"
            class="text-center text-caption"
          >
            {{ $t("deskAssessment.setup.sims.roomBrightnessLabel") }}
            <v-slider
              dense
              class="mt-4 mr-4"
              vertical
              :tick-labels="brightnessLabels"
              max="2"
              min="-2"
              step="1"
              tick-size="0"
              v-model="brightnessValue"
              @change="results.brightnesslevel = brightnessValue"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9">
            <visual-desk-planner
              v-model="deskItems"
              ref="deskPlanner"
              :currentDraggedImageId="currentDraggedImageId"
              :currentDraggedImageOffset="currentDraggedImageOffset"
              :currentDraggedImageVersion="currentDraggedImageVersion"
              :brightnessValue="brightnessValue"
              :workArea="results.workarea"
              @calculateResults="CalculateAndUpdateResults()"
              @workArea="setWorkArea($event)"
              @saveValues="$emit('saveSimsVisualPlanner')"
              @updateImage="value.deskImageUrl = $event"
              :assessmentId="assessmentId"
              :reloadOnUpdate="reloadOnUpdate"
            />
            <v-row justify="center">
              <v-col
                v-if="$vuetify.breakpoint.mdAndDown"
                :cols="5"
                class="text-center text-caption mt-4 pb-0"
              >
                {{ $t("deskAssessment.setup.sims.roomBrightnessLabel") }}
                <v-slider
                  dense
                  max="2"
                  min="-2"
                  step="1"
                  tick-size="0"
                  v-model="brightnessValue"
                  @change="results.brightnesslevel = brightnessValue"
                />
              </v-col>
            </v-row>
            <v-row justify="center" align="center" class="mt-8">
              <v-col
                :cols="setupRating ? 6 : 12"
                :class="setupRating ? 'text-right' : 'text-center'"
                class="mb-0"
              >
                <span class="font-weight-medium body-1">{{ setupText }} </span>
              </v-col>
              <v-col
                v-show="setupRating"
                cols="6"
                class="text-left"
                :class="'headline ' + setupRatingColor + '--text'"
              >
                {{ setupRating }}
                ({{ setupScore }}%)
              </v-col>
            </v-row>

            <v-expand-transition>
              <v-row v-if="standalone && deskItems.length > 2" justify="center">
                <v-col>
                  <div>
                    <v-btn
                      color="secondary"
                      class="px-4"
                      @click="NavigateToSignupPage()"
                      >{{ $t("deskAssessment.setup.sims.standaloneSignUp") }}
                    </v-btn>
                    <div class="text-caption">
                      {{
                        $t("deskAssessment.setup.sims.standaloneSignUpNoCard")
                      }}
                    </div>
                  </div>
                  <p class="mt-5">
                    {{ SignUpTextForSpecificDeskRating }}
                  </p>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="5" v-if="!$vuetify.breakpoint.mdAndDown">
        <div
          v-for="section in SimsComponents"
          :key="section.category"
          class="mb-6 primary--text text-body-2"
        >
          {{ $t(section.category) }}
          <v-row class="pl-3 black--text text-caption">
            <v-col
              v-for="item in section.items"
              :key="item.id"
              class="mr-8 mb-4 pl-0"
            >
              <p>{{ $t(item.displayedName) }}</p>
              <v-img
                class="imagePointer"
                :id="item.id"
                :src="
                  require('@/assets/images/sims/v' +
                    getCurrentImageVersion(item) +
                    '/' +
                    item.imageName)
                "
                draggable="true"
                @pointerdown="onImageSelect"
                @mousedown="onImageSelect"
                :width="item.width"
                :height="item.height"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VisualDeskPlanner from "./VisualDeskPlanner";
import Vue from "vue";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
const schema = require("@/assets/json/DeskAssessment/DeskAssessmentQuestions.json");

export default {
  name: "Sims",
  props: {
    value: Object,
    standalone: {
      type: Boolean,
      default: false
    },
    assessmentId: String,
    reloadOnUpdate: Boolean
  },
  components: {
    VisualDeskPlanner
  },
  data() {
    const SimsComponents = require("@/assets/json/DeskAssessment/SimsComponents.json");
    return {
      SimsComponents: SimsComponents,
      schema: schema,
      simsImages: null,
      deskItems: this.value.deskItems || [],
      results: this.value ?? {},
      currentDraggedImageId: "",
      currentDraggedImageVersion: null,
      currentDraggedImageOffset: { x: 0, y: 0 },
      setupRating: "",
      setupScore: 0,
      brightnessValue: this.value.brightnesslevel || 0,
      brightnessLabels: [
        this.$t("deskAssessment.setup.sims.veryDarkBrightness"),
        this.$t("deskAssessment.setup.sims.darkBrightness"),
        this.$t("deskAssessment.setup.sims.neutralBrightness"),
        this.$t("deskAssessment.setup.sims.brightBrightness"),
        this.$t("deskAssessment.setup.sims.veryBrightBrightness")
      ]
    };
  },
  watch: {
    value: {
      handler() {
        this.results = this.value;
        this.deskItems = this.value.deskItems;
      },
      deep: true
    }
  },
  mounted() {
    this.simsImages = this.SimsComponents.map(a => a.items).flat(1);
    this.CalculateResults();
  },
  methods: {
    onImageSelect(e) {
      this.setImageAndOffsets(e);
      if (e.pointerType && e.pointerType !== "mouse") {
        Vue.nextTick(() => {
          this.$refs.deskPlanner.addCurrentItemToDesk("default");
        });
      }
    },
    preventDefaultDragOver(e) {
      e.preventDefault();
    },
    setImageAndOffsets(e) {
      var selectImage = this.simsImages.find(i => i.id == e.currentTarget.id);
      this.currentDraggedImageId = e.currentTarget.id;
      this.currentDraggedImageVersion =
        this.getCurrentImageVersion(selectImage);

      // Setting drag image with drawn canvas image
      this.currentDraggedImageOffset.x =
        e.clientX - e.target.getBoundingClientRect().left;
      this.currentDraggedImageOffset.y =
        e.clientY - e.target.getBoundingClientRect().top;
    },
    getCurrentImageVersion(image) {
      let itemOnDesk = this.deskItems.find(x => x.id == image.id);
      if (itemOnDesk) {
        return itemOnDesk.version;
      }
      let allImages = require.context("@/assets/images/sims", true);
      let currentImageVersions = allImages
        .keys()
        .filter(x => x.includes(image.imageName));
      let versions = currentImageVersions.map(x =>
        parseInt(x.split("/")[1].substring(1))
      );
      return Math.max(versions);
    },
    CalculateAndUpdateResults() {
      this.CalculateResults();
    },
    CalculateResults() {
      if (this.$refs.deskPlanner) {
        DeskAssessmentResults.CalculateVisualDeskPlannerResults(
          this.schema,
          this.results,
          this.deskItems
        );
        let result = DeskAssessmentResults.getVisualDeskPlannerRating(
          this.results,
          this.$refs.deskPlanner.height
        );
        this.setupRating = result.rating;
        this.setupScore = result.score ? result.score.toFixed() : result.score;

        this.updateSimsValuesToKeep();
      }
    },
    updateSimsValuesToKeep() {
      // populates array that is passed to formgenerator to prevent answers from being removed based on show on
      var arrayToReturn = [];

      let badSeatingOption = this.hasBadSeatingOption();

      if (this.results.armrests != undefined || badSeatingOption) {
        arrayToReturn.push("armrests");
      }

      if (badSeatingOption) {
        arrayToReturn.push("backsupport");
        arrayToReturn.push("seatadjustable");
      }

      this.$emit("updateSavedSimsValues", arrayToReturn);
    },
    hasBadSeatingOption() {
      let hasBadSeating = false;
      let badOptions = ["beanBag", "gymBall", "stool"];
      badOptions.forEach(seating => {
        if (!hasBadSeating) {
          hasBadSeating = DeskAssessmentResults.isMainSeatingOption(
            this.deskItems,
            seating,
            this.results.workarea
          );
        }
      });
      return hasBadSeating;
    },
    NavigateToSignupPage() {
      window.location.href =
        "https://www.vitruevida.com/?utm_source=visualdeskplanner&utm_medium=freetry&utm_campaign=sims#/SignUp";
    },
    setWorkArea(area) {
      this.results.workarea = area;
      this.CalculateAndUpdateResults();
      this.$emit("input", this.results);
    }
  },
  computed: {
    brightnessLabel() {
      switch (this.brightnessValue) {
        case -2:
          return this.brightnessLabels[0];
        case -1:
          return this.brightnessLabels[1];
        case 1:
          return this.brightnessLabels[3];
        case 2:
          return this.brightnessLabels[4];
      }
      return this.brightnessLabels[2];
    },
    setupText() {
      if (!this.setupRating) {
        return this.isMobile()
          ? this.$t("deskAssessment.setup.sims.dragDropInstructionMobile")
          : this.$t("deskAssessment.setup.sims.dragDropInstruction");
      }
      return this.$t("deskAssessment.setup.sims.setupRatingText", {
        0: [
          this.setupRating ===
          this.$t("deskAssessment.setup.sims.rating.needsImprovement")
            ? ""
            : " " + this.$t("deskAssessment.setup.sims.is")
        ]
      });
    },
    setupRatingColor() {
      return DeskAssessmentResults.getVisualDeskPlannerRatingColor(
        this.setupRating
      );
    },
    SignUpTextForSpecificDeskRating() {
      switch (this.setupRating) {
        case "Poor":
          return this.$t("deskAssessment.setup.sims.signUpTextPoor");
        case "Fair":
          return this.$t("deskAssessment.setup.sims.signUpTextFair");
        case "Good":
          return this.$t("deskAssessment.setup.sims.signUpTextGood");
        case "Very Good":
          return this.$t("deskAssessment.setup.sims.signUpTextVeryGood");
        default:
          return "";
      }
    }
  }
};
</script>

<style scoped>
.stickyKonva {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 15%;
}
</style>
