var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" }, on: { dragover: _vm.preventDefaultDragOver } },
    [
      _c(
        "v-row",
        { attrs: { align: "start" } },
        [
          _vm.$vuetify.breakpoint.mdAndDown
            ? _c(
                "v-col",
                { staticClass: "mb-0 pb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-slide-group",
                    { attrs: { "show-arrows": "" } },
                    _vm._l(_vm.simsImages, function(item) {
                      return _c("v-slide-item", { key: item.id }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "black--text text-caption text-center mx-8"
                          },
                          [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(_vm.$t(item.displayedName)))
                            ]),
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height pb-4",
                                attrs: { align: "center", justify: "center" }
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("v-img", {
                                      staticClass: "imagePointer",
                                      attrs: {
                                        id: item.id,
                                        src: require("@/assets/images/sims/v" +
                                          _vm.getCurrentImageVersion(item) +
                                          "/" +
                                          item.imageName),
                                        draggable: "true",
                                        width: item.width,
                                        height: item.height
                                      },
                                      on: {
                                        pointerdown: _vm.onImageSelect,
                                        mousedown: _vm.onImageSelect
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "mt-0 pt-0 stickyKonva",
              attrs: { cols: "12", lg: "7" }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: _vm.$vuetify.breakpoint.mdAndDown
                      ? "center"
                      : "space-between"
                  }
                },
                [
                  !_vm.$vuetify.breakpoint.mdAndDown
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-center text-caption",
                          attrs: { cols: "2", "align-self": "start" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "deskAssessment.setup.sims.roomBrightnessLabel"
                                )
                              ) +
                              " "
                          ),
                          _c("v-slider", {
                            staticClass: "mt-4 mr-4",
                            attrs: {
                              dense: "",
                              vertical: "",
                              "tick-labels": _vm.brightnessLabels,
                              max: "2",
                              min: "-2",
                              step: "1",
                              "tick-size": "0"
                            },
                            on: {
                              change: function($event) {
                                _vm.results.brightnesslevel =
                                  _vm.brightnessValue
                              }
                            },
                            model: {
                              value: _vm.brightnessValue,
                              callback: function($$v) {
                                _vm.brightnessValue = $$v
                              },
                              expression: "brightnessValue"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.mdAndDown ? 12 : 9
                      }
                    },
                    [
                      _c("visual-desk-planner", {
                        ref: "deskPlanner",
                        attrs: {
                          currentDraggedImageId: _vm.currentDraggedImageId,
                          currentDraggedImageOffset:
                            _vm.currentDraggedImageOffset,
                          currentDraggedImageVersion:
                            _vm.currentDraggedImageVersion,
                          brightnessValue: _vm.brightnessValue,
                          workArea: _vm.results.workarea,
                          assessmentId: _vm.assessmentId,
                          reloadOnUpdate: _vm.reloadOnUpdate
                        },
                        on: {
                          calculateResults: function($event) {
                            return _vm.CalculateAndUpdateResults()
                          },
                          workArea: function($event) {
                            return _vm.setWorkArea($event)
                          },
                          saveValues: function($event) {
                            return _vm.$emit("saveSimsVisualPlanner")
                          },
                          updateImage: function($event) {
                            _vm.value.deskImageUrl = $event
                          }
                        },
                        model: {
                          value: _vm.deskItems,
                          callback: function($$v) {
                            _vm.deskItems = $$v
                          },
                          expression: "deskItems"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _vm.$vuetify.breakpoint.mdAndDown
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center text-caption mt-4 pb-0",
                                  attrs: { cols: 5 }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "deskAssessment.setup.sims.roomBrightnessLabel"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("v-slider", {
                                    attrs: {
                                      dense: "",
                                      max: "2",
                                      min: "-2",
                                      step: "1",
                                      "tick-size": "0"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.results.brightnesslevel =
                                          _vm.brightnessValue
                                      }
                                    },
                                    model: {
                                      value: _vm.brightnessValue,
                                      callback: function($$v) {
                                        _vm.brightnessValue = $$v
                                      },
                                      expression: "brightnessValue"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-8",
                          attrs: { justify: "center", align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0",
                              class: _vm.setupRating
                                ? "text-right"
                                : "text-center",
                              attrs: { cols: _vm.setupRating ? 6 : 12 }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-medium body-1" },
                                [_vm._v(_vm._s(_vm.setupText) + " ")]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.setupRating,
                                  expression: "setupRating"
                                }
                              ],
                              staticClass: "text-left",
                              class:
                                "headline " + _vm.setupRatingColor + "--text",
                              attrs: { cols: "6" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.setupRating) +
                                  " (" +
                                  _vm._s(_vm.setupScore) +
                                  "%) "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expand-transition",
                        [
                          _vm.standalone && _vm.deskItems.length > 2
                            ? _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c("v-col", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "px-4",
                                            attrs: { color: "secondary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.NavigateToSignupPage()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "deskAssessment.setup.sims.standaloneSignUp"
                                                )
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-caption" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "deskAssessment.setup.sims.standaloneSignUpNoCard"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("p", { staticClass: "mt-5" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.SignUpTextForSpecificDeskRating
                                          ) +
                                          " "
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.mdAndDown
            ? _c(
                "v-col",
                { attrs: { cols: "12", lg: "5" } },
                _vm._l(_vm.SimsComponents, function(section) {
                  return _c(
                    "div",
                    {
                      key: section.category,
                      staticClass: "mb-6 primary--text text-body-2"
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t(section.category)) + " "),
                      _c(
                        "v-row",
                        { staticClass: "pl-3 black--text text-caption" },
                        _vm._l(section.items, function(item) {
                          return _c(
                            "v-col",
                            { key: item.id, staticClass: "mr-8 mb-4 pl-0" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t(item.displayedName)))
                              ]),
                              _c("v-img", {
                                staticClass: "imagePointer",
                                attrs: {
                                  id: item.id,
                                  src: require("@/assets/images/sims/v" +
                                    _vm.getCurrentImageVersion(item) +
                                    "/" +
                                    item.imageName),
                                  draggable: "true",
                                  width: item.width,
                                  height: item.height
                                },
                                on: {
                                  pointerdown: _vm.onImageSelect,
                                  mousedown: _vm.onImageSelect
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }